import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import Login from './Components/common/Login';
import Ghome from './Components/Pages/Ghome';
import Uhome from './Components/Pages/Uhome'

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Ghome />
          </Route>

          <Route path="/login">
            <Login />
          </Route>

          <Route path="/uhome">
            <Uhome />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
