import React from 'react';
import {
    Link
} from 'react-router-dom';
import logo from "../../../src/QMS.png"

function Ghome() {
    return (
        <div className="container">
            <div className="row">
                <div className="offset-1 offset-sm-2 col-md-4 col-sm-12 mt-5" >
                    <header className="App-header">
                        <img src={logo} className="App-logo" alt="logo" style={{ width: '20rem', height: '20rem', alignSelf: 'right' }} />
                    </header>
                </div>
                <div className="offset-md-2 col-md-4 col-sm-12 mt-5 text-center">
                    <h1 className="mt-5">
                        This is a dev environment for QuizMySkill.
                    </h1>
                    <div className="container mt-5">
                        <Link to="/uhome" className="btn btn-warning shadow">Login to continue</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Ghome;
