import React, { useState } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "./UserPool"

export default function Login({ setToken }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onSubmit = (event) => {
        event.preventDefault();

        const user = new CognitoUser({
            Username: email,
            Pool: UserPool,
        });

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });

        user.authenticateUser(authDetails, {
            onSuccess: (data) => {
                console.log("onSuccess: ", data);
                setToken(data.idToken.jwtToken)
                window.location.replace("http://localhost:3000/uhome");

            },
            onFailure: (err) => {
                console.error("onFailure: ", err);
            },
            newPasswordRequired: (data) => {
                console.log("newPasswordRequired: ", data);
            },
        });
    };

    return (
        <div>
            <div className="container">
                <div className="card" style={{ width: '50rem' }}>
                    <div className="card-header display-6 text-center" style={{ backgroundColor: "#9ae8e3" }}>User Login</div>
                    <div className="card-body">
                        <form onSubmit={onSubmit}>
                            <div className="row text-lest">
                                <div className="offset-3 col-3 text-secondary" style={{ fontSize: "24px" }}>
                                    <label htmlFor="email">Email </label>
                                </div>
                                <div className="col-6 p-1">
                                    <input
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
                                    ></input>
                                </div>
                            </div>
                            <div className="row text-left">
                                <div className="offset-3 col-3 text-secondary" style={{ fontSize: "24px" }}>
                                    <label htmlFor="password">Password</label>
                                </div>
                                <div className="col-6 p-1">
                                    <input
                                        type="password"
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
                                    ></input>
                                </div>
                            </div>
                            <div className="card-footer text-center m-4" >
                                <button className="btn btn-warning shadow" type="submit">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );

};