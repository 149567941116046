import React from 'react';
import Login from '../common/Login';

function setToken(userToken) {
    sessionStorage.setItem('token', JSON.stringify(userToken));
}

function getToken() {
    const tokenString = sessionStorage.getItem('token');
    console.log("tokenString:", tokenString)
    const userToken = JSON.parse(tokenString);
    console.log("userToken", userToken)
    return userToken
}

function UHome() {
    const token = getToken();

    if (!token) {
        return (<Login setToken={setToken} />);
    }

    return (
        <div className="wrapper m-4">
            <h1>Application</h1>
        </div>
    );
};

export default UHome;